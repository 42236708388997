.pro-sidebar {
	height: 100%;
	position: relative;
	width: 270px;
	min-width: 270px;
	color: rgba(0, 0, 0, 0.6);
	background: #FFFFFF;
}

.pro-sidebar > .pro-sidebar-inner {
height: 100%;
	background: #FFFFFF;
}

div.pro-sidebar {
	position: relative;
	width: 270px;
	min-width: 270px;
	background: #FFFFFF;
}

.pro-sidebar .pro-menu > ul > .pro-sub-menu > .pro-inner-list-item {
	background-color: #FFFFFF;
}

main {
	padding: 24px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
}