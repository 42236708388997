.pro-sidebar {
	color: rgba(0, 0, 0, 0.6);
	background: #FFFFFF;
}

.pro-sidebar > .pro-sidebar-inner {

	background: #FFFFFF;
}

div.pro-sidebar {

	background: #FFFFFF;
}

.pro-sidebar .pro-menu > ul > .pro-sub-menu > .pro-inner-list-item {
	background-color: #FFFFFF;
}

#projects {
	display: flex;
}